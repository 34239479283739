<div class="lg:mx-[12.5%] md:mx-[8%] mx-[2%] min-[1560px]:flex min-[1560px]:justify-center">
    <div class="max-w-[1200px] w-full py-3">
        <app-contract-info-legality-review [data]="contractInfoData" [isFormDisabled]="true">
        </app-contract-info-legality-review>
        <div class="lg:flex flex-col !mt-2 general-information-ota-section mx-auto">
            <div class="bg-palette-base-white rounded-lg border">
                <div class="!p-6 lg:!w-full flex items-start !gap-3">
                    <!-- Icon collapse -->
                    <button (click)="isCollapsed = !isCollapsed"
                        class="!p-1 flex items-center justify-center cursor-pointer hover:bg-palette-gray-100 rounded-lg">
                        <i
                            class="text-[28px] leading-[28px] font-medium text-palette-gray-700 {{ isCollapsed ? 'sctr-icon-chevron-right' : 'sctr-icon-chevron-down'}}"></i>
                    </button>
                    <div>
                        <p class="font-inter !text-lg !font-semibold text-palette-gray-900">{{ "OTA.CONTRACT_AGREEMENT.PROPERTY_INFORMATION" | translate }}</p>
                        <p class="font-inter !text-sm !font-normal text-palette-gray-700 !mt-1">{{ "OTA.CONTRACT_AGREEMENT.PROPERTY_CLICK" | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="!p-1 lg:!w-full" [ngClass]="{'hidden': isCollapsed}">
                <app-view-general-info-legality-review [data]="generalInfoData" [managerSystem]="managerSystem"
                    [isCM]="isCM"></app-view-general-info-legality-review>
            </div>
        </div>
        <!-- <app-payment-info [data]="paymentInfoData"></app-payment-info> -->
    </div>
</div>