<div
    class="contact m-0 !mt-2 grid w-full grid-cols-1 !gap-6 rounded-lg border border-palette-gray-200 bg-palette-base-white lg:grid-cols-3">
    <div class="col-span-1 flex flex-col !p-6 max-lg:!pb-0">
        <div class="flex items-center !gap-3">
            <div>
                <p class="font-inter text-[18px] font-semibold leading-7 text-palette-gray-900">
                    {{ "OTA.CONTACT.CONTACT" | translate }}
                </p>
                <p class="text-[14px] font-normal leading-5 text-palette-gray-700">
                    {{ "OTA.CONTACT.FILL_CONTACT" | translate }}
                </p>
            </div>
        </div>
    </div>
    <div class="col-span-1 flex flex-col items-start justify-center !gap-4 !p-6 max-lg:!pt-0 lg:col-span-2 lg:!pl-0">
        <form [formGroup]="contactForm" class="w-full bg-palette-gray-25 pointer-events-none">
            <div class="!w-[534x] rounded-lg border border-system-gray-300 !p-4">
                <div class="flex flex-col items-center justify-center !gap-4">
                    <div
                        class="flex h-[24px] w-full items-center justify-start font-inter text-base font-semibold text-palette-gray-900">
                        {{ "OTA.CONTACT.MAIN_CONTACT" | translate }}
                    </div>
                    <div class="flex-input flex w-full flex-col gap-1 custom-input">
                        <label for="full-name-input" class="form-title">{{ "OTA.CONTACT.FULL_NAME" | translate }}
                        </label>
                        <input pInputText class="!h-[44px] !min-h-[44px] w-full rounded-md text-[14px]"
                            id="full-name-input" type="text" name="full_name" formControlName="full_name"
                            [placeholder]="'OTA.CONTACT.ENTER_FULL_NAME' | translate" class="form-input" [ngClass]="{
                'is-invalid': f['full_name'].touched && f['full_name'].errors,
                'is-disable': contactForm.disabled
              }" />

                        <div *ngIf="f['full_name'].touched && f['full_name'].errors" class="invalid-feedback">
                            <div *ngIf="f['full_name'].errors['required']" class="flex items-center gap-1">
                                <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                                {{ "OTA.CONTACT.FULL_NAME" | translate }}
                                {{ "OTA.CONTACT.IS_REQUIRED" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="flex-input flex w-full flex-col gap-1 custom-input">
                        <label for="email-input" class="form-title">Email </label>
                        <input pInputText class="!h-[44px] !min-h-[44px] w-full rounded-md text-[14px]" id="email-input"
                            type="text" name="email" formControlName="email"
                            [placeholder]="'OTA.CONTACT.ENTER_EMAIL' | translate" class="form-input" [ngClass]="{
                'is-invalid': f['email'].touched && f['email'].errors, 'is-disable': contactForm.disabled
              }" />
                        <div *ngIf="f['email'].touched && f['email'].errors" class="invalid-feedback">
                            <div *ngIf="f['email'].errors['required']" class="flex items-center gap-1">
                                <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                                Email {{ "OTA.CONTACT.IS_REQUIRED" | translate }}
                            </div>
                            <div *ngIf="f['email'].errors['pattern']" class="flex items-center gap-1">
                                <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                                Email {{ "OTA.CONTACT.IS_INVALID" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="flex !w-full flex-col gap-1 custom-input">
                        <label for="phone_number" class="form-title">{{ "OTA.CONTACT.PHONE_NUMBER" | translate }}
                        </label>
                        <div class="container-phone-input flex items-center" [ngClass]="{
                'is-invalid':
                  f['phone_number'].touched && f['phone_number'].errors,
                'is-disable': contactForm.disabled
              }">
                            <p-dropdown [options]="countries" optionLabel="code" [filter]="true" filterBy="name,code"
                                formControlName="phone_number_code">
                                <ng-template pTemplate="selectedItem">
                                    <div *ngIf="f['phone_number_code'].value"
                                        class="flex items-center justify-center gap-2">
                                        <img [src]="getIcon(f['phone_number_code'].value)" style="width: 18px" />
                                        <div>{{ f["phone_number_code"].value.code }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-country pTemplate="item">
                                    <div class="z-1000 flex items-center gap-2">
                                        <img [src]="getIcon(country)" style="width: 18px" />
                                        <span>{{ country.name }}</span>
                                        <span>({{ country.code }})</span>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <input pKeyFilter="int" id="phone_number" name="phone_number" formControlName="phone_number"
                                [placeholder]="'OTA.CONTACT.ENTER_PHONE' | translate"
                                class="phone-input w-full rounded-lg" maxlength="15" />
                        </div>
                        <div *ngIf="f['phone_number'].touched && f['phone_number'].errors" class="invalid-feedback">
                            <div *ngIf="f['phone_number'].errors['required']" class="flex items-center gap-1">
                                <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                                {{ "OTA.CONTACT.PHONE_NUMBER" | translate }}
                                {{ "OTA.CONTACT.IS_REQUIRED" | translate }}
                            </div>
                            <div *ngIf="f['phone_number'].errors['pattern']" class="flex items-center gap-1">
                                <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                                {{ "OTA.CONTACT.PHONE_NUMBER" | translate }}
                                {{ "OTA.CONTACT.IS_INVALID" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="flex-input flex !w-full flex-col gap-1  custom-input">
                        <label for="office_phone_number" class="form-title">{{ "OTA.CONTACT.OFFICE_PHONE" | translate
                            }}</label>
                        <div class="container-phone-input flex items-center" [ngClass]="{
                'is-invalid':
                  f['office_phone_number'].touched &&
                  f['office_phone_number'].errors,
                'is-disable': contactForm.disabled
              }">
                            <p-dropdown [options]="countries" optionLabel="code" [filter]="true" filterBy="name"
                                formControlName="office_phone_number_code">
                                <ng-template pTemplate="selectedItem">
                                    <div *ngIf="f['office_phone_number_code'].value"
                                        class="flex items-center justify-center gap-2">
                                        <img [src]="getIcon(f['office_phone_number_code'].value)" style="width: 18px" />
                                        <div>{{ f["office_phone_number_code"].value.code }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-country pTemplate="item">
                                    <div class="flex items-start gap-2">
                                        <img class="flex items-start text-[14px] leading-5" [src]="getIcon(country)"
                                            style="width: 18px" />
                                        <span class="flex items-start text-[14px] leading-5">{{
                                            country.name
                                            }}</span>
                                        <span class="flex text-[14px] leading-5">{{
                                            country.code
                                            }}</span>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <input pKeyFilter="int" id="office_phone_number" name="office_phone_number"
                                formControlName="office_phone_number"
                                [placeholder]="'OTA.CONTACT.ENTER_OFFICE_PHONE' | translate"
                                class="phone-input w-full rounded-lg" maxlength="15" />
                        </div>
                        <div *ngIf="
                f['office_phone_number'].touched &&
                f['office_phone_number'].errors
              " class="invalid-feedback">
                            <div *ngIf="f['office_phone_number'].errors['pattern']" class="flex items-center gap-1">
                                <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                                {{ "OTA.CONTACT.PHONE_NUMBER" | translate }}
                                {{ "OTA.CONTACT.IS_INVALID" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="flex-input flex w-full flex-col gap-1 custom-input">
                        <label for="position-input" class="form-title">{{
                            "OTA.CONTACT.POSITION" | translate
                            }}</label>
                        <input pInputText
                            class="!h-[44px] !max-h-[44px] !min-h-[44px] w-full rounded-md border text-[14px]"
                            id="position-input" type="text" name="position" formControlName="position"
                            [ngClass]="{'is-disable': contactForm.disabled}"
                            [placeholder]="'OTA.CONTACT.ENTER_DEPARTMENT' | translate" class="form-input" />
                    </div>
                    <div class="flex-input flex h-[20px] w-full gap-2">
                        <p-checkbox [binary]="true" inputId="binary" formControlName="contactable_24h"></p-checkbox>
                        <span
                            class="flex h-[20px] items-center text-[14px] font-medium leading-5 text-palette-gray-700">{{
                            "OTA.CONTACT.CONTACTABLE" | translate }}</span>
                    </div>
                </div>
            </div>
        </form>
        <ng-container>
            <div *ngFor="let subContact of subContacts.controls; index as i" class="w-full">
                <app-sub-contact [subContact]="subContact" class="col-span-1 !w-full lg:col-span-2" (close)="close(i)">
                </app-sub-contact>
            </div>
        </ng-container>
    </div>
</div>