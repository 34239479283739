import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { renderStar } from '@shared/utils/helper';
import {
  AbstractControl,
  FormGroup,
  Validators,
  FormBuilder,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import { GeneralInfoDTO } from '@booking/angular-booking-user-data-service';
import { ChannelManagerControllerService } from '@booking/angular-booking-generic-service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-general-info-legality-review',
  templateUrl: './general-info-legality-review.component.html',
  styleUrls: ['./general-info-legality-review.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class GeneralInfoLegalityReviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input('generalInfo') generalInfo: GeneralInfoDTO | any;
  @Input() isDiscardChange: boolean;
  @Input() managerSystem: any;
  @Input() isCM: boolean;
  public generalForm: FormGroup;
  public starRatingValue: any;
  public isAdvanceQuality: boolean = false;
  public advanceQuality: any;
  public particularValue: any;
  public selectedManagerSystem: any;
  isAutoSync: boolean;
  onDestroy$: Subject<void> = new Subject();

  public starRatings: any[] = [
    { name: 'N/A', key: '0', count: 0 },
    { name: '', key: '1', count: 1 },
    { name: '', key: '2', count: 2 },
    { name: '', key: '3', count: 3 },
    { name: '', key: '4', count: 4 },
    { name: '', key: '5', count: 5 },
  ];

  public particulars: any[] = [
    { name: 'OTA.GENERAL_INFORMATION.YES', key: 'A', control: 'group' },
    { name: 'OTA.GENERAL_INFORMATION.NO', key: 'M', control: 'group' },
  ];

  public channels: any[] = [
    { name: 'OTA.GENERAL_INFORMATION.YES', key: 'A', control: 'channel' },
    { name: 'OTA.GENERAL_INFORMATION.NO', key: 'M', control: 'channel' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private readonly formGroupDirective: FormGroupDirective,
    private channelManagerControllerService: ChannelManagerControllerService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['isDiscardChange'] ||
      (changes['generalInfo'] && this.generalInfo)
    ) {
      this.isAutoSync =
        this.generalInfo?.channel_manager_property_id ||
        !this.generalInfo?.channel_manager_auth_key
          ? true
          : false;
      this.generalForm?.patchValue({
        propertyName: this.generalInfo?.property_name,
        entityName: this.generalInfo?.legal_entity_name,
        description: this.generalInfo?.description,
        selectedStarRating: this.starRatings?.find(
          (star) => star?.count == Math.floor(this.generalInfo?.star)
        ),
        selectedParticular: this.generalInfo?.property_chain_name
          ? this.particulars[0]
          : this.particulars[1],
        selectedChannel:
          this.generalInfo?.channel_manager_name ||
          this.generalInfo?.channel_manager_other_name
            ? this.channels[0]
            : this.channels[1],
        channel: this.managerSystem?.find(
          (m: any) => m.channel_id === this.generalInfo?.channel_manager_id
        ),
        group: this.generalInfo?.property_chain_name,
        channelOthers: this.generalInfo?.channel_manager_other_name,
        channelPropertyId: this.generalInfo?.channel_manager_property_id,
        channelPropertyAuthenKey: this.generalInfo?.channel_manager_auth_key,
      });
      //IF a half of star
      if (this.generalInfo?.star % 1 === 0.5) {
        this.advanceQuality = true;
        this.isAdvanceQuality = true;
      }

      if (this.generalInfo?.description && this.generalInfo?.description != null) {
        const editorDiv = this.el.nativeElement.querySelector('.ql-editor');
        this.renderer.setProperty(editorDiv, 'innerHTML', this.generalInfo?.description);
      }

      if (this.generalForm) {
        this.isAutoSync = this.f['channel']?.value?.is_auto_sync;
        this.generalInfo?.channel_manager_property_id &&
          this.f['channelPropertyId'].setValidators(Validators.required);
        this.generalInfo?.channel_manager_auth_key &&
          this.f['channelPropertyAuthenKey'].setValidators(Validators.required);
        this.generalInfo?.channel_manager_other_name &&
          this.f['channelOthers'].setValidators(Validators.required);

        this.f['channelOthers'].updateValueAndValidity();
        this.f['channelPropertyId'].updateValueAndValidity();
        this.f['channelPropertyAuthenKey'].updateValueAndValidity();
      }
    }
  }

  ngOnInit(): void {
    this.generalForm = this.formBuilder.group({
      propertyName: ['', Validators.required],
      entityName: ['', Validators.required],
      description: ['', Validators.required],
      selectedStarRating: [, Validators.required],
      selectedAdvanceQuality: [false],
      selectedParticular: [this.particulars[1]],
      selectedChannel: [this.channels[1]],
      group: [''],
      channel: [''],
      channelOthers: [null],
      channelPropertyId: [null],
      channelPropertyAuthenKey: [null],
    });
    this.starRatingValue = this.generalForm.get('selectedStarRating')!.value;
    this.particularValue = this.generalForm.get('selectedParticular')!.value;
    this.formGroupDirective?.form?.addControl('generalInfo', this.generalForm);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  handleClickChannel(data: any) {
    if (this.f['channel'].value?.channel_name === 'Others') {
      this.f['channelOthers'].setValidators(Validators.required);
    } else {
      this.f['channelOthers'].clearValidators();
    }
    this.isAutoSync = data?.value.is_auto_sync;
    if (this.f['selectedChannel'].value.key === 'A') {
      if (!this.isAutoSync) {
        this.f['channelPropertyId'].setValidators(Validators.required);
        this.f['channelPropertyAuthenKey'].setValidators(Validators.required);
      } else if (this.isAutoSync) {
        this.f['channelPropertyId'].setValidators(Validators.required);
      }
    } else {
      this.f['channelPropertyId'].clearValidators();
      this.f['channelPropertyAuthenKey'].clearValidators();
    }
    this.f['channelOthers'].updateValueAndValidity();
    this.f['channelPropertyId'].updateValueAndValidity();
    this.f['channelPropertyAuthenKey'].updateValueAndValidity();
  }

  setRequiredFields(isHasCM: boolean, isHasOther?: boolean) {
    if (isHasCM) {
      this.f['channelPropertyId'].setValidators(Validators.required);
      this.f['channelPropertyAuthenKey'].setValidators(Validators.required);
    } else {
      this.f['channelPropertyId'].clearValidators();
      this.f['channelPropertyAuthenKey'].clearValidators();
    }
    if (isHasOther) {
      this.f['channelOthers'].setValidators(Validators.required);
    } else {
      this.f['channelOthers'].clearValidators();
    }
    this.f['channelOthers'].updateValueAndValidity();
    this.f['channelPropertyId'].updateValueAndValidity();
    this.f['channelPropertyAuthenKey'].updateValueAndValidity();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.generalForm?.controls;
  }

  public hanldeAdvanceQuality() {
    if (this.isAdvanceQuality) {
      this.advanceQuality = undefined;
      this.isAdvanceQuality = false;
    } else {
      this.isAdvanceQuality = true;
      this.advanceQuality = true;
    }
  }

  handleChangeCheckBox(checkboxField: any) {
    const requiredField = this.generalForm.get(checkboxField.control)!;
    if (checkboxField.key === 'A') {
      requiredField.setValidators(Validators.required);
    } else {
      requiredField.clearValidators();
      this.setRequiredFields(false, false);
    }
    requiredField.updateValueAndValidity();
  }

  public arrayStar(number: number): any {
    return renderStar(number);
  }
}
