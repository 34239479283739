import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LanguageAndCurrency {

     private _changeLanguageCurrency: BehaviorSubject<any> = new BehaviorSubject<any>(null);
     changeLanguageCurrency$: Observable<any> = this._changeLanguageCurrency.asObservable();
 
     changeLanguageCurrency() {
       this._changeLanguageCurrency.next('');
     }
   
     notifyChangeLanguageCurrency() {
       return this.changeLanguageCurrency$;
     }
}
