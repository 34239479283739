<div
    class="lg:flex items-start !mt-2 bg-palette-base-white rounded-lg border  general-information-ota-section mx-auto ">
    <div class="!p-6 lg:!w-[31.55%] flex items-center !gap-3">
        <!-- Icon collapse -->
        <!-- <button (click)="isCollapsed = !isCollapsed" 
            class="!p-1 flex items-center justify-center cursor-pointer hover:bg-palette-gray-100 rounded-lg">
            <i class="text-[28px] leading-[28px] font-medium text-palette-gray-700 {{ isCollapsed ? 'sctr-icon-chevron-right' : 'sctr-icon-chevron-down'}}"></i>
        </button> -->
        <div>
            <p class="font-inter !text-lg !font-semibold text-palette-gray-900">{{ "OTA.CONTRACT_AGREEMENT.CONTRACT_AGREEMENT" | translate }}</p>
            <p class="font-inter !text-sm !font-normal text-palette-gray-700 !mt-1">{{ "OTA.CONTRACT_AGREEMENT.INFO" | translate }}</p>
        </div>
    </div>
    <div class="!p-6 max-lg:!pt-0 lg:!w-[68.45%]">
        <form [formGroup]="contractAgreementForm" class="pointer-events-none">
            <ng-container *ngIf="!isLoading; then form; else skeleton"></ng-container>
            <ng-template #form>
                <div [ngClass]="{'disabled-approve': isFormDisabled}">
                    <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{
                        "OTA.CONTRACT_AGREEMENT.FULL_NAME" | translate }}
                    </p>
                    <input
                        class="font-inter !text-sm !font-normal text-palette-gray-700 !mt-1 input-contracts-agreement"
                        formControlName="fullName" [placeholder]="'OTA.CONTRACT_AGREEMENT.P_FULL_NAME' | translate"
                        [ngClass]="{ 'is-invalid': f['fullName'].touched && f['fullName'].errors, 'is-disable': isFormDisabled }"
                        type="text">
                    <div *ngIf="f['fullName'].touched && f['fullName'].errors" class="!mt-1 is-feedback"
                        aria-disabled="true">
                        <div *ngIf="f['fullName'].errors['required']">
                            <div [message-error]="'OTA.CONTRACT_AGREEMENT.FULL_NAME' | translate"></div>
                        </div>
                    </div>
                </div>
                <div class="!mt-4" [ngClass]="{'disabled-approve': isFormDisabled}">
                    <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{ "OTA.CONTRACT_AGREEMENT.ROLE" |
                        translate }} </p>
                    <div class="xl:!flex max-xl:!flex-none max-xl:space-y-4 !gap-4 !mt-2">
                        <div *ngFor="let rule of rules"
                            class="border !h-[56px] rounded-lg xl:!w-[50%] flex justify-between !py-3 !px-4 items-center hover:bg-palette-gray-100 hover:cursor-pointer"
                            [ngClass]="{'opacity-70': isFormDisabled}"
                            [class.active-rule]="rule === contract.selectedRule" (click)="handleSelectRuleClick(rule)">
                            <div class="flex justify-start !gap-3 items-center">
                                <i [ngClass]="addClassActiveRule(rule, contract)"
                                    class="!text-xl !leading-[20px] text-palette-gray-500">
                                </i>
                                <p [ngClass]="{ '!text-palette-blue-600': rule === contract.selectedRule }"
                                    class="font-inter !text-sm !font-medium text-palette-gray-700">
                                    {{ rule.name | translate }}
                                </p>
                            </div>
                            <div>
                                <p-radioButton [(ngModel)]="contract.selectedRule" [inputId]="rule.key"
                                    formControlName="selectedRule" [value]="rule">
                                </p-radioButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="!mt-4" [ngClass]="{'disabled-approve': isFormDisabled}">
                    <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{ "OTA.CONTRACT_AGREEMENT.EMAIL"
                        | translate }} </p>
                    <input
                        class="font-inter !text-sm !font-normal text-palette-gray-700 !mt-1 input-contracts-agreement"
                        [ngClass]="{ 'is-invalid': f['email'].touched && f['email'].errors, 'is-disable': isFormDisabled }"
                        formControlName="email" [placeholder]="'OTA.CONTRACT_AGREEMENT.P_EMAIL' | translate"
                        type="text">
                    <div *ngIf="f['email'].touched && f['email'].errors" class="!mt-1 is-feedback">
                        <div *ngIf="f['email'].errors['required']" class="flex items-center gap-1">
                            <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                            {{ "OTA.ERROR_MESSAGE.EMAIL" | translate }}
                        </div>
                    </div>
                    <div *ngIf="f['email'].touched && f['email'].errors && f['email'].errors['pattern']"
                        class="!mt-1 is-feedback">
                        <div class="flex items-center gap-1">
                            <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                            {{ "OTA.CONTRACT_AGREEMENT.V_EMAIL" | translate }}
                        </div>
                    </div>
                </div>
                <div class="!mt-4" [ngClass]="{'disabled-approve phone-input-disabled': isFormDisabled}">
                    <label for="phoneNumber" class="font-inter !mb-1 !text-sm !font-medium text-palette-gray-700">{{
                        "OTA.CONTRACT_AGREEMENT.PHONE_NUMBER" | translate }}</label>
                    <app-input-phone-number #phoneNumberRef [id]="'phoneNumber'" [phone]="phoneNumber">
                    </app-input-phone-number>
                </div>
                <div *ngIf="contract.selectedRule" class="!mt-4">
                    <div class="flex justify-between items-center">
                        <span class="font-inter !text-sm !font-semibold text-palette-gray-700">{{
                            "OTA.CONTRACT_AGREEMENT.ATTACHMENTS" | translate }}
                        </span>
                        <div *ngIf="!isFormDisabled">
                            <button *ngIf="contract.selectedRule.key === '0' && !(maximumAttachBusiness === 0)"
                                class="flex items-center !gap-1 !py-1 !px-2 hover:bg-palette-blue-50 rounded-lg"
                                (click)="addUploadCpn('OTA.CONTRACT_AGREEMENT.ADDITION_ATTACHMENT', false, false, 'business')">
                                <i class="sctr-icon-plus !text-base !leading-[16px] text-palette-blue-600"></i>
                                <span class="font-inter !text-sm !font-semibold text-palette-blue-600">{{
                                    "OTA.CONTRACT_AGREEMENT.ADD_NEW" | translate }}</span>
                            </button>
                            <button *ngIf="contract.selectedRule.key === '1' && !(maximumAttachPersonal === 0)"
                                class="flex items-center !gap-1"
                                (click)="addUploadCpn('OTA.CONTRACT_AGREEMENT.ADDITION_ATTACHMENT', false, false, 'personal')">
                                <i class="sctr-icon-plus !text-base !leading-[16px] text-palette-blue-600"></i>
                                <span class="font-inter !text-sm !font-semibold text-palette-blue-600">{{
                                    "OTA.CONTRACT_AGREEMENT.ADD_NEW" | translate }}</span>
                            </button>
                        </div>
                    </div>
                    <div class="!mt-3">
                        <span *ngIf="contract.selectedRule.key === '0'; then business; else personal"></span>
                        <ng-template #business>
                            <div *ngFor="let uploadCpn of uploadAttachBusiness; let i = index">
                                <div class="hover:cursor-pointer" (click)="previewImg(media[i])">
                                    <app-upload
                                        [ngClass]="{'disabled-approve upload-file-input-disabled': isFormDisabled}"
                                        [title]="uploadCpn.title" [type]="uploadCpn.type" [attach]="uploadCpn"
                                        (close)="removeUploadCpn(i, 'business')"
                                        (upload)="uploadFile($event, 'business', i)">
                                    </app-upload>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #personal>
                            <div *ngFor="let uploadCpn of uploadAttachPersonal; let i = index">
                                <div class="hover:cursor-pointer" (click)="previewImg(media[i])">
                                    <app-upload
                                        [ngClass]="{'disabled-approve upload-file-input-disabled': isFormDisabled}"
                                        [title]="uploadCpn.title" [type]="uploadCpn.type" [attach]="uploadCpn"
                                        (close)="removeUploadCpn(i, 'personal')"
                                        (upload)="uploadFile($event, 'personal', i)">
                                    </app-upload>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div class=" bg-palette-gray-100 !p-2 flex !gap-2 border rounded-lg">
                        <i class="sctr-icon-alert-circle text-palette-gray-500"></i>
                        <p class="font-inter !text-xs !font-normal text-palette-gray-700">{{ warningText | translate }}
                        </p>
                    </div>
                    <div *ngIf="isWarningRule && !checkFilesValid()" class="!mt-1 is-feedback">
                        <div class="flex items-center gap-1">
                            <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                            {{ "OTA.CONTRACT_AGREEMENT.ATTACHMENTS_VALID" | translate }}
                        </div>
                    </div>
                </div>

                <div class="flex !mt-4 !gap-2" [ngClass]="{'disabled-approve': isFormDisabled}">
                    <p-checkbox [value]="true" formControlName="checkboxAgree"
                        [ngClass]="{ 'is-invalid': f['checkboxAgree'].touched && f['checkboxAgree'].errors }">
                    </p-checkbox>
                    <p class="text-sm font-medium text-palette-gray-700">{{ 'OTA.CONTRACT_AGREEMENT.AUTHORIZED_CONTRACT'
                        | translate }}</p>
                </div>
            </ng-template>

            <ng-template #skeleton>
                <div class="flex flex-col !gap-4" [ngClass]="{'disabled-approve': isFormDisabled}">
                    <div>
                        <p-skeleton width="5rem"></p-skeleton>
                        <p-skeleton height="2.75rem" styleClass="mt-1" borderRadius="8px"></p-skeleton>
                    </div>
                    <div>
                        <p-skeleton width="6rem"></p-skeleton>
                        <div class="flex !flex-col xl:!flex-row !gap-4 mt-1">
                            <div class="w-full">
                                <p-skeleton height="3.5rem" borderRadius="8px"></p-skeleton>
                            </div>
                            <div class="w-full">
                                <p-skeleton height="3.5rem" borderRadius="8px"></p-skeleton>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p-skeleton width="3rem"></p-skeleton>
                        <p-skeleton height="2.75rem" styleClass="mt-1" borderRadius="8px"></p-skeleton>
                    </div>
                    <div>
                        <p-skeleton width="6rem"></p-skeleton>
                        <p-skeleton height="2.75rem" styleClass="mt-1" borderRadius="8px"></p-skeleton>
                    </div>
                    <div>
                        <p-skeleton width="5.5rem"></p-skeleton>
                        <p-skeleton height="2.75rem" styleClass="mt-1" borderRadius="8px"></p-skeleton>
                    </div>
                    <p-skeleton width="50%"></p-skeleton>
                </div>
            </ng-template>
        </form>
    </div>
    <app-toast [keyToast]="'FILE_ERROR'"></app-toast>