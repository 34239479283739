import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  Validators,
  FormBuilder,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import { GeneralInfoDTO } from '@booking/angular-booking-user-data-service';
import { LanguagesServiceControllerService } from '@soctrip/angular-regional-service';
import { takeUntil, timeout, Subject } from 'rxjs';
import { TIME_OUT_REQUEST } from '@shared/constant';

@Component({
  selector: 'app-language-legality-review',
  templateUrl: './language-legality-review.component.html',
  styleUrls: ['./language-legality-review.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class LanguageLegalityReviewComponent implements OnInit, OnChanges {

@Input('generalInfo') generalInfo: GeneralInfoDTO;
  @Input() isDiscardChange: boolean;
  onDestroy$: Subject<void> = new Subject();
  languages: any[];
  currencies = [
    { name: 'USD', code: 'USD' },
    { name: 'VND', code: 'VND' },
    { name: 'EUR', code: 'EUR' },
    { name: 'INR', code: 'INR' },
    { name: 'AUD', code: 'AUD' },
  ];
  formGroup!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private readonly formGroupDirective: FormGroupDirective,
    private languagesServiceControllerService: LanguagesServiceControllerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['isDiscardChange'] ||
      (changes['generalInfo'] && this.generalInfo)
    ) {
      this.formGroup?.patchValue({
        selectedlanguages: this.generalInfo?.languages,
        currency: this.currencies.find(
          (c) => c.code === this.generalInfo?.currency
        ),
      });
    }
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      selectedlanguages: [['en'], Validators.required],
      currency: [this.currencies[0], Validators.required],
    });
    this.formGroupDirective?.form?.addControl('language', this.formGroup);
    this.getListLanguages();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  getListLanguages() {
    this.languagesServiceControllerService
      .getTranslationSupported()
      .pipe(timeout(TIME_OUT_REQUEST), takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res.success && res.data) {
          this.languages = res.data;
        }
      });
  }


}
