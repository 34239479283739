<div class="room-details-modal flex flex-col !gap-4 relative font-inter !rounded-xl bg-white !p-6 min-[940px]:max-w-[920px] w-[55vw] max-h-[80vh] aspect-[11/9] mx-auto">  
    <div class="flex items-center justify-between">
        <div class="flex items-start font-inter text-lg whitespace-nowrap !font-semibold text-black">View image</div>
        <div class="flex items-center justify-center hover:bg-palette-gray-100 rounded-full min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px]">
            <i class="sctr-icon-x-close flex items-center justify-center font-inter whitespace-nowrap text-palette-gray-600 cursor-pointer text-2xl" (click)="onclose()"></i>
        </div>        
    </div>
    <div class="bg-black rounded-lg">
        <img class="w-[872px] object-cover aspect-[16/10] bg-palette-gray-200"
            src="{{galleria[activeIndex]}}" (error)="replaceWithDefaultImage($event)" alt="">
    </div>
    <div class="w-full relative galleria-room-details ">
        <div *ngIf="showButton"
            [ngClass]="activeIndex == 0 ? 'text-palette-gray-500 opacity-40 cursor-default': 'text-palette-gray-700 cursor-pointer hover:border-palette-gray-500'"
            (click)="prev()"
            class="absolute top-1/2 left-4 -translate-y-1/2 z-10 galleria-prev rounded-[20px] btn-shadow border bg-white border-palette-gray-400 flex items-center justify-center h-7 w-7 min-w-[36px] max-w-[36px] min-h-[36px] max-h-[36px]">
            <i class="sctr-icon-chevron-left text-[20px]"></i>
        </div>
        <div #listElement class="flex overflow-hidden gap-1 !rounded-lg w-full max-w-[872px]">
            <div *ngFor="let item of galleria; index as idx">
                <div #imageElement id="image-{{idx}}" (click)="handleImageClick(idx)" class="w-fit p-galleria-thumbnail-item"
                [ngClass]="idx == activeIndex? idx==0  ? 'p-galleria-thumbnail-item-current p-galleria-thumbnail-item-start': 
                    idx== galleria.length-1 ? 'p-galleria-thumbnail-item-current p-galleria-thumbnail-item-end': 'p-galleria-thumbnail-item-current': ''">
                    <img class="thumbnail-image-item cursor-pointer object-cover rounded min-w-[132px] aspect-square bg-palette-gray-200 select-none" [src]="item" (error)="replaceWithDefaultImage($event)" />
                </div>
            </div>
        </div>
        <div *ngIf="showButton"
            [ngClass]="activeIndex == galleria.length - 1 ? 'text-palette-gray-500 opacity-40 cursor-default': 'text-palette-gray-700 cursor-pointer hover:border-palette-gray-500'"
            class="absolute z-10 top-1/2 right-4 -translate-y-1/2 galleria-next rounded-[20px] btn-shadow border bg-white border-palette-gray-400 flex items-center justify-center h-7 w-7 min-w-[36px] max-w-[36px] min-h-[36px] max-h-[36px]"
            (click)="next()">
            <i class="sctr-icon-chevron-right text-[20px]"></i>
        </div>
    </div>
</div>