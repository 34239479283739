<div class="ota-custom flex flex-row justify-center items-start">
    <form [formGroup]="generalInfoForm" novalidate class="w-full">
        <app-general-info-legality-review
            [generalInfo]="data"
            [managerSystem]="managerSystem"
            [isCM]="isCM">
        </app-general-info-legality-review>
        <app-location-legality-review [generalInfo]="data"></app-location-legality-review>
        <app-language-legality-review [generalInfo]="data"></app-language-legality-review>
        <app-floors-rooms-legality-review [generalInfo]="data"></app-floors-rooms-legality-review>
        <app-contact-legality-review [generalInfo]="data"></app-contact-legality-review>
    </form>
</div>
