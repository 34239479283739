
<div class="lg:flex items-start !mt-2 bg-palette-base-white rounded-lg border general-information-ota-section">
    <div class="!p-6 lg:!w-[31.55%] flex items-center !gap-3">
        <div>
            <p class="font-inter !text-lg !font-semibold text-palette-gray-900">{{
                "OTA.GENERAL_INFORMATION.GENERAL_INFORMATION" | translate }}</p>
            <p class="font-inter !text-sm !font-normal text-palette-gray-700 !mt-1">{{ "OTA.GENERAL_INFORMATION.INFO" | translate }}</p>
        </div>
    </div>
    <div class="!p-6 lg:!w-[68.45%]">
        <form [formGroup]="generalForm" class="pointer-events-none">
            <div class="custom-input">
                <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{
                    "OTA.GENERAL_INFORMATION.PROPERTY_NAME" | translate }} <span class="text-palette-red-600">*</span>
                </p>
                <input
                    class="{{ f['propertyName'].touched && f['propertyName'].errors && f['propertyName'].errors['required'] ? 'ng-invalid ng-dirty' : ''}} !mt-1"
                    pInputText formControlName="propertyName" [ngClass]="{'is-disable': generalForm.disabled}"
                    [placeholder]="'OTA.GENERAL_INFORMATION.ENTER_PROPERTY_NAME' | translate" type="text">
                <div *ngIf="f['propertyName'].touched && f['propertyName'].errors" class="!mt-1 invalid-feedback">
                    <div *ngIf="f['propertyName'].errors['required']" class="flex items-center gap-1">
                        <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                        {{ "OTA.ERROR_MESSAGE.PROPERTY_NAME" | translate }}
                    </div>
                </div>
            </div>
            <div class="!mt-4 custom-input">
                <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{
                    "OTA.GENERAL_INFORMATION.LEGAL_ENTITY_NAME" | translate }} <span
                        class="text-palette-red-600">*</span></p>
                <input
                    class="{{ f['entityName'].touched && f['entityName'].errors && f['entityName'].errors['required'] ? 'ng-invalid ng-dirty' : ''}} !mt-1"
                    pInputText formControlName="entityName" [ngClass]="{'is-disable': generalForm.disabled}"
                    [placeholder]="'OTA.GENERAL_INFORMATION.ENTER_LEGAL_ENTITY_NAME' | translate" type="text">
                <div *ngIf="f['entityName'].touched && f['entityName'].errors" class="!mt-1 invalid-feedback">
                    <div *ngIf="f['entityName'].errors['required']" class="flex items-center gap-1">
                        <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                        {{ "OTA.ERROR_MESSAGE.LEGAL_ENTITY_NAME" | translate }}
                    </div>
                </div>
            </div>
            <div class="!mt-4">
                <p class="font-inter !text-sm !font-medium text-palette-gray-700 mb-1">{{
                    "OTA.GENERAL_INFORMATION.DESCRIPTION" | translate}} <span class="text-palette-red-600">*</span></p>
                <p-editor formControlName="description"
                    [placeholder]="'OTA.GENERAL_INFORMATION.ENTER_DESCRIPTION' | translate"
                    [ngClass]="{'is-disable': generalForm.disabled}" [readonly]="generalForm.disabled"
                    [style]="{height:'144px'}">
                    <ng-template pTemplate="header">
                        <span class="ql-formats">
                            <button class="ql-bold"></button>
                            <button class="ql-italic"></button>
                            <button class="ql-underline"></button>
                            <button class="ql-strike"></button>
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                            <button class="ql-list" value="check"></button>
                        </span>
                    </ng-template>
                </p-editor>
                <div *ngIf="f['description'].touched && f['description'].errors" class="!mt-1 invalid-feedback">
                    <div *ngIf="f['description'].errors['required']" class="flex items-center gap-1">
                        <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                        {{ "OTA.ERROR_MESSAGE.DESCRIPTION" | translate }}
                    </div>
                </div>
            </div>
            <div class="custom-input">
                <p class="font-inter !text-sm !font-medium text-palette-gray-700 mt-1">{{
                    "OTA.GENERAL_INFORMATION.STAR_RATING" | translate}} <span class="text-palette-red-600">*</span></p>
                <div class="!mt-3 items-center">
                    <div *ngFor="let star of starRatings" class="field-checkbox">
                        <p-radioButton [inputId]="star.key" formControlName="selectedStarRating"
                            [ngClass]="{'is-disable': generalForm.disabled}" [(ngModel)]="starRatingValue"
                            [value]="star"></p-radioButton>
                        <label *ngIf="star.count == 0" class="font-inter !text-xs !leading-[20px] !font-medium"
                            [for]="star.key">{{ star.name }}</label>
                        <label [for]="star.key" class="space-x-[2px]">
                            <span *ngFor="let item of arrayStar(star.count)">
                                <i class="sctr-icon-solid-star-01 text-[#FAC515] !text-xl !leading-[20px]"></i>
                            </span>
                        </label>
                    </div>
                    <div *ngIf="f['selectedStarRating'].touched && f['selectedStarRating'].errors"
                        class="!mt-1 invalid-feedback">
                        <div *ngIf="f['selectedStarRating'].errors['required']" class="flex items-center gap-1">
                            <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                            {{ "OTA.GENERAL_INFORMATION.SELECT_STAR_RATING" | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="starRatingValue && starRatingValue?.count !== 0 && starRatingValue?.count !== 5" class="!mt-3">
                <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{
                    "OTA.GENERAL_INFORMATION.ADVANCE_QUALITY" | translate }}</p>
                <div class="flex !gap-8 !mt-3">
                    <div class="field-checkbox !mb-0">
                        <p-radioButton [value]="isAdvanceQuality" formControlName="selectedAdvanceQuality"
                            [disabled]="generalForm.disabled" [(ngModel)]="advanceQuality"
                            (click)="hanldeAdvanceQuality()"></p-radioButton>
                        <label class="ml-2 font-inter !text-xs !leading-[20px] !font-medium">{{
                            "OTA.GENERAL_INFORMATION.ADVANCE05" | translate}}</label>
                    </div>
                </div>
            </div>
            <div class="custom-input !mt-3">
                <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{ "OTA.GENERAL_INFORMATION.CHAIN" |
                    translate }}</p>
                <div class="flex !gap-8 !mt-3">
                    <div *ngFor="let particular of particulars" class="field-checkbox !mb-0">
                        <p-radioButton (onClick)="handleChangeCheckBox(particular)" [inputId]="particular.key"
                            formControlName="selectedParticular" [value]="particular"
                            [(ngModel)]="particularValue"></p-radioButton>
                        <label [for]="particular.key" class="ml-2 font-inter !text-xs !leading-[20px] !font-medium">{{
                            particular.name | translate }}</label>
                    </div>
                </div>
            </div>
            <div *ngIf="particularValue.key === 'A'" class="custom-input !mt-4">
                <input
                    class="{{ f['group'].touched && f['group'].errors && f['group'].errors['required'] ? 'ng-invalid ng-dirty' : ''}} !mt-1"
                    [ngClass]="{'is-disable': generalForm.disabled}" pInputText formControlName="group"
                    [placeholder]="'OTA.GENERAL_INFORMATION.CONPANY' | translate" type="text">
                <div *ngIf="f['group'].touched && f['group'].errors" class="!mt-1 invalid-feedback">
                    <div *ngIf="f['group'].errors['required']" class="flex items-center gap-1">
                        <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                        {{ "OTA.ERROR_MESSAGE.ENTER_CONPANY" | translate }}
                    </div>
                </div>
            </div>
            <div class="custom-input !mt-3">
                <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{ "OTA.GENERAL_INFORMATION.CHANNEL" |
                    translate }}</p>
                <div class="flex !gap-8 !mt-3">
                    <div *ngFor="let channel of channels" class="field-checkbox !mb-0">
                        <p-radioButton (onClick)="handleChangeCheckBox(channel)" [inputId]="channel.key"
                            [value]="channel" formControlName="selectedChannel"></p-radioButton>
                        <label [for]="channel.key" class="ml-2 font-inter !text-xs !leading-[20px] !font-medium">{{
                            channel.name | translate }}</label>
                    </div>
                </div>
            </div>


            <div *ngIf="f['selectedChannel'].value.key === 'A'"
                class="bg-palette-gray-25 border border-palette-gray-200 rounded-lg !p-4 !my-4">
                <div class="flex flex-col gap-1 ota-location-form">
                    <label class="input-title">{{ 'OTA.GENERAL_INFORMATION.CHANNEL_MANAGER_SYSTEM' | translate }} <span
                            class="text-palette-red-600">*</span></label>
                    <p-dropdown formControlName="channel" class="!w-full" [options]="managerSystem"
                        (onChange)="handleClickChannel($event)"
                        [placeholder]="'OTA.GENERAL_INFORMATION.SELECT_CHANNEL' | translate"
                        optionLabel="channel_name"></p-dropdown>
                    <div *ngIf="f['channel'].touched && f['channel'].errors" class="!mt-1 invalid-feedback">
                        <div *ngIf="f['channel'].errors['required']">
                            <div [message-error]="'OTA.GENERAL_INFORMATION.CHANNEL_MANAGER_SYSTEM' | translate"></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="f['channel'].value?.channel_name == 'Others'"
                    class="flex flex-col gap-1 custom-input !mt-4">
                    <label for="channelOthers" class="input-title">{{
                        'OTA.GENERAL_INFORMATION.OTHER_CHANNEL_MANAGER_SYSTEM' |
                        translate }} <span class="text-palette-red-600">*</span></label>
                    <input pInputText type="text" id="channelOthers" formControlName="channelOthers"
                        [ngClass]="{'is-disable': generalForm.disabled}"
                        [placeholder]="('COMMON.ENTER' | translate) + ' ' + ('OTA.GENERAL_INFORMATION.OTHER_CHANNEL_MANAGER_SYSTEM' | translate)">
                    <div *ngIf="f['channelOthers'].touched && f['channelOthers'].errors" class="!mt-1 invalid-feedback">
                        <div *ngIf="f['channelOthers'].errors['required']" class="flex items-center gap-1">
                            <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                            {{ "OTA.GENERAL_INFORMATION.OTHER_CHANNEL_MANAGER_SYSTEM" | translate }} {{
                            "OTA.IS_REQUIRED" |
                            translate }}
                        </div>
                    </div>
                </div>
                <div *ngIf="f['channel'].value?.channel_id" class="flex flex-col gap-1 custom-input !mt-4">
                    <label for="channelPropertyId" class="input-title">{{
                        'OTA.GENERAL_INFORMATION.CHANNEL_MANAGER_PROPERTY_ID' |
                        translate }} <span class="text-palette-red-600">*</span></label>
                    <input pInputText type="text" id="channelPropertyId" formControlName="channelPropertyId"
                        [ngClass]="{'is-disable': generalForm.disabled || isCM}"
                        [placeholder]="('COMMON.ENTER' | translate) + ' ' +('OTA.GENERAL_INFORMATION.CHANNEL_MANAGER_PROPERTY_ID' | translate)">
                    <div *ngIf="f['channelPropertyId'].touched && f['channelPropertyId'].errors"
                        class="!mt-1 invalid-feedback">
                        <div *ngIf="f['channelPropertyId'].errors['required']">
                            <div [message-error]="'OTA.GENERAL_INFORMATION.CHANNEL_MANAGER_PROPERTY_ID' | translate">
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isAutoSync === false" class="flex flex-col gap-1 custom-input !mt-4">
                    <label for="channelPropertyId" class="input-title">{{
                        'OTA.GENERAL_INFORMATION.CHANNEL_MANAGER_AUTHENTICATION_KEY' |
                        translate }} <span class="text-palette-red-600">*</span></label>
                    <input pInputText type="text" id="channelPropertyAuthenKey"
                        [ngClass]="{'is-disable': generalForm.disabled || isCM}" formControlName="channelPropertyAuthenKey"
                        [placeholder]="('COMMON.ENTER' | translate) + ' ' +('OTA.GENERAL_INFORMATION.CHANNEL_MANAGER_AUTHENTICATION_KEY' | translate)">
                    <div *ngIf="f['channelPropertyAuthenKey'].touched && f['channelPropertyAuthenKey'].errors"
                        class="!mt-1 invalid-feedback">
                        <div *ngIf="f['channelPropertyAuthenKey'].errors['required']">
                            <div
                                [message-error]="'OTA.GENERAL_INFORMATION.CHANNEL_MANAGER_AUTHENTICATION_KEY' | translate">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>