import {
  Component,
  Input,
  Renderer2,
  OnInit,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { IHotelList, PropertyAmenityList } from '@modules/hotel-booking/models/hotel.model';
import { Router } from '@angular/router';
import { createSlug } from '@shared/utils/helper';
import { MessageService } from 'primeng/api';
import { BookMarkService } from '@modules/hotel-booking/services/bookmark/bookmark.service';
import { ToastType } from '@shared/enum/toast-type';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { environment } from '@env/environment';
import { UserService } from 'src/app/core/services/user/user.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hot-deal-hotel-card',
  templateUrl: './hot-deal-hotel-card.component.html',
  styleUrls: ['./hot-deal-hotel-card.component.scss'],
  providers: [MessageService, TranslateModule],
})

export class HotDealHotelCardComponent implements OnInit {
  @Input() item: IHotelList;

  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;
  pipe = new DatePipe('en-US');
  invalidIconName: string = 'check-circle-broken';
  isBookmarked: boolean;
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  isLogin: boolean | null;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private bookMarkService: BookMarkService,
    private messageService: MessageService,
    private userService: UserService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.checkIsLogin();
    this.checkBookmark();
  }

  checkIsLogin() {
    this.userService.getLoginStatus().subscribe(isLogin => {
      this.isLogin = isLogin;
    });
  }

  checkBookmark() {
    this.bookMarkService.isBookmarkExists(this.item.property_id).subscribe({
      next: (isBookmarked) => {
        this.isBookmarked = isBookmarked;
      }
    });
  }

  handleBookmark(event: any) {
    event.stopPropagation();
    if(!this.isLogin) {
      this.messageService.add({
        key: 'hd',
        severity: ToastType.WARNING,
        detail: 'Please login before bookmark',
      });
      return;
    }
    if(this.isBookmarked) {
      this.bookMarkService.removeBookMark(this.item.property_id).subscribe({
        next: (response) => {
          if(response.success) {
            this.isBookmarked = false;
            this.bookMarkService.getBloomFilter();
            this.bookMarkService.recentSavedList$.next({ type: 'remove', property: this.item });
            this.messageService.add({
              key: 'hd',
              severity: ToastType.SUCCESS,
              detail: this.translateService.instant('BOOKMARK.DELETE'),
            });
          }
        },
      });
    } else {
      this.bookMarkService.addBookMark(this.item.property_id).subscribe({
        next: (response) => {
          if(response.success) {
            this.isBookmarked = true;
            this.bookMarkService.getBloomFilter();
            this.bookMarkService.recentSavedList$.next({ type: 'add', property: this.item });
            this.messageService.add({
              key: 'hd',
              severity: ToastType.SUCCESS,
              detail: this.translateService.instant('BOOKMARK.ADD'),
            });
          }
        },
      });
    }
  }

  genRouterLink(data: any) {
    const addressArray = data.city_name.split(',');
    let cityNameSlug = createSlug(data.city_name);
    if (cityNameSlug == '' || !cityNameSlug) {
      cityNameSlug = 'property';
    }
    let hotelNameSlug = createSlug(data.property_name);
    if (hotelNameSlug == '' || !hotelNameSlug) {
      hotelNameSlug = 'hotel-name';
    }
    return `/booking/hotel/search/${cityNameSlug}/${hotelNameSlug}`;
  }

  genQueryParams(id: string, city_name: string) {
    return {
      property_id: id,
      destination_name: city_name,
      is_continue_booking: true,
    };
  }

  openMap(event: any, item: IHotelList) {
    event.stopPropagation();
    const queryParams = {
      property_id: item.property_id,
    };
    this.router.navigate(['/booking/hotel/map', this.item.property_name], {
      queryParams,
    });
  }

  renderAmenityList(property_amenity_list: PropertyAmenityList[]) {
    let availableList = property_amenity_list.filter(
      (item) => item.amenity_icon !== this.invalidIconName
    );
    return availableList;
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/temporary/default-hotel.png';
  }
}
