<div class="flex items-start !mt-2 bg-palette-base-white rounded-lg border general-information-ota-section">
    <div class="!p-6 !w-[31.55%] flex items-center !gap-3">
        <div>
            <p class="font-inter !text-lg !font-semibold text-palette-gray-900">{{ "OTA.FLOOROOM.FLOOROOM" | translate }} </p>
            <p class="font-inter !text-sm !font-normal text-palette-gray-700 !mt-1">{{ "OTA.FLOOROOM.INFO" | translate }} </p>
        </div>
    </div>
    <div class="!p-6 !w-[68.45%]">
        <form [formGroup]="formGroup" class="pointer-events-none">
            <div class="grid grid-cols-2 !m-0 !gap-4">
                <div class="custom-input">
                    <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{ "OTA.FLOOROOM.N_FLOOR" |
                        translate }}</p>
                    <div class="!mt-1">
                        <p-inputNumber placeholder="0" formControlName="floor" mode="decimal" [min]="0" [max]="1000"
                            [ngClass]="{'is-disable': formGroup.disabled}"
                            [useGrouping]="false"
                            class="{{ f['floor'].touched && f['floor'].errors && f['floor'].errors['required'] ? 'ng-invalid ng-dirty' : ''}} !mt-1">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="custom-input">
                    <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{ "OTA.FLOOROOM.N_ROOM" |
                        translate }}</p>
                    <div class="!mt-1">
                        <p-inputNumber placeholder="0" formControlName="room" mode="decimal" [min]="0" [max]="1000"
                            [ngClass]="{'is-disable': formGroup.disabled}"
                            [useGrouping]="false"
                            class="{{ f['room'].touched && f['room'].errors && f['room'].errors['required'] ? 'ng-invalid ng-dirty' : ''}} !mt-1">>
                        </p-inputNumber>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>