import { HttpClient, HttpClientModule } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ApplicationConfig } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { SoctripMapModule } from '@soctrip-common/map';
import { AUTH_INTERCEPTOR_PROVIDER } from './core/interceptors/auth/auth.interceptor';
import {
  ApiModule as PropertyDataServiceApiModule,
  BASE_PATH as PROPERTY_DATA_SERVICE_API_BASE_PATH,
} from '@booking/angular-booking-property-data-service';
import { ApiModule as AngularMapLocationServiceApiModule } from '@assistant/angular-map-location-service';
import { ApiModule as AngularTourBuilderServiceApiModule } from '@assistant/angular-tour-builder-service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  ApiModule as RatingServiceApiModule,
  BASE_PATH as RATING_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-rating-service';
import {
  ApiModule as StorageServiceApiModule,
  BASE_PATH as STORAGE_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-storage-service';
import {
  ApiModule as PropertySearchServiceApiModule,
  BASE_PATH as PROPERTY_SEARCH_SERVICE_API_BASE_PATH,
} from '@booking/angular-booking-property-search-service';
import {
  ApiModule as FamousSpotServiceApiModule,
  BASE_PATH as FAMOUS_SPOT_SERVICE_API_BASE_PATH,
} from '@booking/booking-famous-spot-service';
import {
  ApiModule as BookingGenericServiceApiModule,
  BASE_PATH as BOOKING_GENERIC_SERVICE_API_BASE_PATH,
} from '@booking/angular-booking-generic-service';
import { environment } from '@env/environment';
import {
  ApiModule as BookmarkServiceApiModule,
  BASE_PATH as BOOKMARK_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-bookmarks-service';
import {
  ApiModule as ShipmentServiceApiModule,
  BASE_PATH as SHIPMENT_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-shipment-service';
import {
  ApiModule as PropertyRoomApiModule,
  BASE_PATH as PROPERTY_ROOM_API_BASE_PATH,
} from '@booking/angular-booking-property-room-service';
import {
  ApiModule as BookingPricingApiModule,
  BASE_PATH as BOOKING_PRICING_API_BASE_PATH,
} from '@booking/booking-pricing-service';
import {
  ApiModule as BookingUserDataApiModule,
  BASE_PATH as BOOKING_USER_DATA_API_BASE_PATH,
} from '@booking/angular-booking-user-data-service';
import {
  ApiModule as BookingFlightApiModule,
  BASE_PATH as BOOKING_FLIGHT_API_BASE_PATH,
} from '@booking/angular-booking-flight-search-service';
import {
  ApiModule as EcommerceFinanceApiModule,
  BASE_PATH as ECOMMERCE_FINANCE_API_BASE_PATH,
} from '@soctrip/angular-ecommerce-finance-service';
import {
  ApiModule as EcommerceWalletApiModule,
  BASE_PATH as ECOMMERCE_WALLET_API_BASE_PATH,
} from '@soctrip/angular-ecommerce-wallet-service';
import {
  ApiModule as OrderApiModule,
  BASE_PATH as ORDER_API_BASE_PATH,
} from '@soctrip/angular-order-service';
import {
  ApiModule as BookingReservationApiModule,
  BASE_PATH as BOOKING_RESERVATION_API_BASE_PATH,
} from '@booking/angular-booking-reservation-service';
import {
  ApiModule as RegionalApiModule,
  BASE_PATH as REGIONAL_API_BASE_PATH,
} from '@soctrip/angular-regional-service';
import {
  ApiModule as AirportInfoControllerService,
  BASE_PATH as BOOKING_FLIGHT_BASE_PATH,
} from '@booking/angular-booking-flight-data-service';
import {
  ApiModule as BookingDashboardSerrvice,
  BASE_PATH as BOOKING_DASHBOARD,
} from '@booking/angular-booking-dashboard-service';
import {
  ApiModule as BookingAdminApiModule,
  BASE_PATH as BOOKING_ADMIN_BASE_PATH,
} from '@booking/angular-booking-admin-service';
import {
  ApiModule as BookingFamousSpotService,
  BASE_PATH as BOOKING_FAMOUS_SPOT_API_BASE_PATH
} from '@booking/angular-booking-famous-spot-service';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { AppSecurityModule } from '@soctrip-common/app-security';
import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
import {
  ApiModule as BannerApiModule,
  BASE_PATH as BANNER_API_BASE_PATH,
} from '@soctrip/angular-banner-service';
import { MessageService } from 'primeng/api';
import { defineCustomElements } from '@soctrip-common/discount-selector/loader';

defineCustomElements(window);

registerLocaleData(localeVi, 'vi');

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    MessageService,
    importProvidersFrom([
      /* @angular */
      HttpClientModule,
      ReactiveFormsModule,
      PropertyDataServiceApiModule,
      AngularMapLocationServiceApiModule,
      AngularTourBuilderServiceApiModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => {
            return new TranslateHttpLoader(http, './assets/i18n/', '.json');
          },
          deps: [HttpClient],
        },
      }),
      SoctripMapModule.setEnvironment(
        environment.MAP_URL,
        environment.MAP_ROUTING_URL,
        environment.BASE_API_URL
      ),
      RatingServiceApiModule,
      PropertySearchServiceApiModule,
      FamousSpotServiceApiModule,
      BookingGenericServiceApiModule,
      BookmarkServiceApiModule,
      ShipmentServiceApiModule,
      StorageServiceApiModule,
      PropertyRoomApiModule,
      BookingPricingApiModule,
      BookingUserDataApiModule,
      BookingFlightApiModule,
      EcommerceFinanceApiModule,
      EcommerceWalletApiModule,
      OrderApiModule,
      BookingReservationApiModule,
      RegionalApiModule,
      AirportInfoControllerService,
      BookingDashboardSerrvice,
      BookingAdminApiModule,
      BannerApiModule,
      BookingFamousSpotService,
      AppSecurityModule.forRoot()
    ]),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.socialAuthenIds.google,
              {
                oneTapEnabled: false, // <===== default is true
              }
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              environment.socialAuthenIds.facebook
            ),
          },
        ],
        onError: (err: any) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    provideAnimations(),
    provideRouter(APP_ROUTES),
    AUTH_INTERCEPTOR_PROVIDER,
    {
      provide: PROPERTY_DATA_SERVICE_API_BASE_PATH,
      useValue: environment.PROPERTY_DATA_SERVICE_API_BASE_PATH,
    },
    {
      provide: RATING_SERVICE_API_BASE_PATH,
      useValue: environment.RATING_SERVICE_API_BASE_PATH,
    },
    {
      provide: PROPERTY_SEARCH_SERVICE_API_BASE_PATH,
      useValue: environment.PROPERTY_SEARCH_SERVICE_API_BASE_PATH,
    },
    {
      provide: FAMOUS_SPOT_SERVICE_API_BASE_PATH,
      useValue: environment.FAMOUS_SPOT_SERVICE_API_BASE_PATH,
    },
    {
      provide: BOOKING_GENERIC_SERVICE_API_BASE_PATH,
      useValue: environment.BOOKING_GENERIC_SERVICE_API_BASE_PATH,
    },
    {
      provide: BOOKMARK_SERVICE_API_BASE_PATH,
      useValue: environment.BOOKMARK_SERVICE_API_BASE_PATH,
    },
    {
      provide: SHIPMENT_SERVICE_API_BASE_PATH,
      useValue: environment.SHIPMENT_SERVICE_API_BASE_PATH,
    },
    {
      provide: STORAGE_SERVICE_API_BASE_PATH,
      useValue: environment.STORAGE_SERVICE_API_BASE_PATH,
    },
    {
      provide: PROPERTY_ROOM_API_BASE_PATH,
      useValue: environment.PROPERTY_ROOM_API_BASE_PATH,
    },
    {
      provide: BOOKING_PRICING_API_BASE_PATH,
      useValue: environment.BOOKING_PRICING_API_BASE_PATH,
    },
    {
      provide: BOOKING_USER_DATA_API_BASE_PATH,
      useValue: environment.BOOKING_USER_DATA_API_BASE_PATH,
    },
    {
      provide: BOOKING_FLIGHT_API_BASE_PATH,
      useValue: environment.BOOKING_FLIGHT_API_BASE_PATH,
    },
    {
      provide: ECOMMERCE_FINANCE_API_BASE_PATH,
      useValue: environment.ECOMMERCE_FINANCE_API_BASE_PATH,
    },
    {
      provide: ECOMMERCE_WALLET_API_BASE_PATH,
      useValue: environment.ECOMMERCE_WALLET_API_BASE_PATH,
    },
    { provide: ORDER_API_BASE_PATH, useValue: environment.ORDER_API_BASE_PATH },
    {
      provide: BOOKING_RESERVATION_API_BASE_PATH,
      useValue: environment.BOOKING_RESERVATION_API_BASE_PATH,
    },
    {
      provide: REGIONAL_API_BASE_PATH,
      useValue: environment.REGIONAL_API_BASE_PATH,
    },
    {
      provide: BOOKING_FLIGHT_BASE_PATH,
      useValue: environment.BOOKING_FLIGHT_BASE_PATH,
    },
    {
      provide: BOOKING_DASHBOARD,
      useValue: environment.BOOKING_DASHBOARD,
    },
    {
      provide: BOOKING_ADMIN_BASE_PATH,
      useValue: environment.BOOKING_ADMIN_BASE_PATH,
    },
    {
      provide: BOOKING_FAMOUS_SPOT_API_BASE_PATH,
      useValue: environment.BOOKING_FAMOUS_SPOT_API_BASE_PATH,
    },
    {
      provide: BANNER_API_BASE_PATH,
      useValue: environment.BANNER_API_BASE_PATH,
    },
  ],
};
