import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IHotelList, PropertyAmenityList } from '@modules/hotel-booking/models/hotel.model';
import { Router } from '@angular/router';
import { BookMarkService } from '@modules/hotel-booking/services/bookmark/bookmark.service';
import { ToastType } from '@shared/enum/toast-type';
import { MessageService } from 'primeng/api';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { environment } from '@env/environment';
import { createSlug } from '@shared/utils/helper';
import { UserService } from 'src/app/core/services/user/user.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-featured-hotel-card',
  templateUrl: './featured-hotel-card.component.html',
  styleUrls: ['./featured-hotel-card.component.scss'],
  providers: [MessageService, TranslateModule],
})
export class FeaturedHotelCardComponent implements OnInit {
  @Input() item: IHotelList;

  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  isBookmarked: boolean;
  isLogin: boolean | null;
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;
  invalidIconName: string = 'check-circle-broken';

  constructor(
    private router: Router,
    private bookMarkService: BookMarkService,
    private messageService: MessageService,
    private userService: UserService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.checkIsLogin();
    this.checkBookmark();
  }

  checkIsLogin() {
    this.userService.getLoginStatus().subscribe(isLogin => {
      this.isLogin = isLogin;
    });
  }

  checkBookmark() {
    this.bookMarkService.isBookmarkExists(this.item.property_id).subscribe({
      next: (isBookmarked) => {
        this.isBookmarked = isBookmarked;
      }
    });
  }

  handleBookmark(event: any) {
    event.stopPropagation();
    if(!this.isLogin) {
      this.messageService.add({
        key: 'fh',
        severity: ToastType.WARNING,
        detail: 'Please login before bookmark',
      });
      return;
    }
    if(this.isBookmarked) {
      this.bookMarkService.removeBookMark(this.item.property_id).subscribe({
        next: (response) => {
          if(response.success) {
            this.isBookmarked = false;
            this.bookMarkService.getBloomFilter();
            this.bookMarkService.recentSavedList$.next({ type: 'remove', property: this.item });
            this.messageService.add({
              key: 'fh',
              severity: ToastType.SUCCESS,
              detail: this.translateService.instant('BOOKMARK.DELETE'),
            });
          }
        },
      });
    } else {
      this.bookMarkService.addBookMark(this.item.property_id).subscribe({
        next: (response) => {
          if(response.success) {
            this.isBookmarked = true;
            this.bookMarkService.getBloomFilter();
            this.bookMarkService.recentSavedList$.next({ type: 'add', property: this.item });
            this.messageService.add({
              key: 'fh',
              severity: ToastType.SUCCESS,
              detail: this.translateService.instant('BOOKMARK.ADD'),
            });
          }
        },
      });
    }
  }

  openMap(event: any, item: IHotelList) {
    event.stopPropagation();
    const queryParams = {
      property_id: item.property_id,
    };
    this.router.navigate(['/booking/hotel/map', this.item.property_name], {
      queryParams,
    });
  }

  genRouterLink(id: string, name: string, address: string, city_name: string) {
    const addressArray = city_name.split(',');
    let urlSlug = createSlug(addressArray[addressArray.length - 1]);
    if (urlSlug == '' || !urlSlug) {
      urlSlug = 'property';
    }
    return `/booking/hotel/search/${urlSlug}/${createSlug(name)}`;
  }

  genQueryParams(id: string, city_name: string) {
    return {
      property_id: id,
      destination_name: city_name,
      is_continue_booking: true,
    };
  }

  createSlug(text: string) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  renderAmenityList(property_amenity_list: PropertyAmenityList[]) {
    let availableList = property_amenity_list.filter(
      (item) => item.amenity_icon !== this.invalidIconName
    );
    return availableList;
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/temporary/default-hotel.png';
  }
}
