import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { GeneralInfoDTO } from '@booking/angular-booking-user-data-service';

@Component({
  selector: 'app-view-general-info-legality-review',
  templateUrl: './view-general-info-legality-review.component.html',
  styleUrls: ['./view-general-info-legality-review.component.scss']
})
export class ViewGeneralInfoLegalityReviewComponent {
  @Input() data: GeneralInfoDTO;
  @Input() managerSystem: any;
  @Input() isCM: boolean;

  generalInfoForm: FormGroup = new FormGroup({
    dummy: new FormControl(''),
  });

}
