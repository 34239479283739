import { Directive, Input, SimpleChanges } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Directive({
    selector: '[seoInfo]'
})
export class SeoInfoDirective {
    @Input() seoData: any;

    constructor(
        private metaService: Meta,
        private titleService: Title
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['seoData'] && this.seoData) {
            this.updateMetaTags();
        }
    }

    private updateMetaTags(): void {
        // Update the page title
        this.titleService.setTitle(this.seoData?.property_name);
        // Update meta tags
        this.metaService.updateTag({ property: 'og:title', content: this.seoData?.property_name });
        this.metaService.updateTag({ name: 'twitter:title', content: this.seoData?.property_name });

        const description = this.getSeoDescription(
            this.seoData?.property_description,
            this.seoData?.property_name,
            this.seoData?.property_address
        );

        this.metaService.updateTag({
            name: 'description',
            content: description
        });
        this.metaService.updateTag({
            property: 'og:description',
            content: description
        });
        this.metaService.updateTag({
            name: 'twitter:description',
            content: description
        });
        this.metaService.updateTag({ property: 'og:image', content: this.seoData?.property_image_overview_url });
        this.metaService.updateTag({ name: 'twitter:image', content: this.seoData?.property_image_overview_url });
    }

    private stripHtml(htmlString: string): string {
        if (htmlString) {
            return htmlString.replace(/<\/?[^>]+(>|$)/g, '');  // Remove HTML tags
        }
        return '';
    }

    private getSeoDescription(description: string | null, name: string, address: string): string {
        if (!description) {
            return `${name} ${address}`;
        } else {
            const strippedDescription = this.stripHtml(description);
            return strippedDescription.length > 200
                ? strippedDescription.substring(0, 200) + '...'  
                : strippedDescription;
        }
    }
}
