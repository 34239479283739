import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormGroupDirective,
  ControlContainer,
} from '@angular/forms';
import { GeneralInfoDTO } from '@booking/angular-booking-user-data-service';

@Component({
  selector: 'app-floors-rooms-legality-review',
  templateUrl: './floors-rooms-legality-review.component.html',
  styleUrls: ['./floors-rooms-legality-review.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class FloorsRoomsLegalityReviewComponent implements OnChanges {
  @Input('generalInfo') generalInfo: GeneralInfoDTO;

  formGroup: FormGroup;
  
  constructor(
    private formBuilder: FormBuilder,
    private readonly formGroupDirective: FormGroupDirective
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDiscardChange'] || changes['generalInfo'] && this.generalInfo) {
      this.formGroup?.patchValue({
        floor: this.generalInfo?.total_floors,
        room: this.generalInfo?.total_rooms,
      });
    }
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      floor: [, Validators.required],
      room: [, Validators.required],
    });
    this.formGroupDirective?.form?.addControl('floorAndRoom', this.formGroup);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }


}
