import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CtaSigninService } from '@modules/hotel-booking/services/cta-signin/cta-signin.service';
import { Media, RatingControllerService } from '@soctrip/angular-rating-service';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '@modules/hotel-booking/services/header/header.service';
import { FooterService } from '@modules/hotel-booking/services/footer/footer.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '@env/environment';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { ShowImageModalComponent } from '@shared/components/show-image-modal/show-image-modal.component';
import { SocchatService } from '@modules/hotel-booking/services/socchat-sevice/socchat.service';
import { OVERALL_RATING_TEXT, OVERALL_RATING_TEXT_CONTENT } from '@shared/constant';

@Component({
  selector: 'app-hotel-review-approval-rating',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './hotel-review-approval-rating.component.html',
  styleUrls: ['./hotel-review-approval-rating.component.scss']
})
export class HotelReviewApprovalRatingComponent implements OnInit{
  @ViewChild('reviewContent') reviewContent: ElementRef;
  
  ratingId: string;
  item: any;
  isExpanded: boolean = true;
  isHelpful: boolean = false;
  isReported: boolean = false;
  isLongText: boolean = false;
  openImageRef: DynamicDialogRef | undefined;
  focusedImageIndex = -1;
  baseUrl = environment.BASE_PATH;
  OVERALL_RATING_TEXT_CONTENT = OVERALL_RATING_TEXT_CONTENT;
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;
  listTypeRoomToolTip: string = '';

  constructor(
    private headerService: HeaderService,
    private footerService: FooterService,
    private ctaSigninService: CtaSigninService,
    private ratingControllerService: RatingControllerService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private ref: DynamicDialogRef,
    public dialogService: DialogService,
    private socchatService: SocchatService,
  ) {
    this.getRatingId();
    this.getRatingObject();
  }

  ngOnInit(): void {
    this.headerService.setPropertyHeader({
      isShow: false,
      isFixed: false,
      isChangeColor: false,
      isWhite: false,
    });
    this.socchatService.setFloatChatBtn(false);
    this.footerService.setIsShowFooter(false);
    this.ctaSigninService.hideCTA();
    this.listTypeRoomToolTip = "<p> "
    for (let i = 1; i < this.item.factors.length; i++) {
      this.listTypeRoomToolTip += this.item.factors[i].type
      this.listTypeRoomToolTip += '<br>';
    }
    this.listTypeRoomToolTip += '</p>';
  }

  ngOnDestroy() {
    if (this.openImageRef) {
      this.ref.close();
    }
  }

  getRatingId() {
    this.ratingId = this.activatedRoute.snapshot.paramMap.get('rating_id')!;
  }

  getRatingObject() {
    this.ratingControllerService.ratingsIdObjectGet(this.ratingId).subscribe({
      next: (data) => {
        if(data.success) {
          this.item = data.data;
        }
      }
    })
  }

  toggleContent(): void {
    this.isExpanded = !this.isExpanded;
  }

  handleClickHelpful(): void {
    this.isHelpful = !this.isHelpful;
  }

  handleClickReport(): void {
    this.isReported = !this.isReported;
  }

  replaceAvatarWithDefaultImage(event: any): void {
    event.target.src = `${this.baseUrl}${this.item.created_by.avatar_thumbnail_url}`;
  }

  handleShowViewMore(els: ElementRef, height: number): void {
    const elementHeight = els?.nativeElement?.offsetHeight;
    if (elementHeight > height) {
      this.isLongText = true;
    } else {
      this.isLongText = false;
    }
    this.isExpanded = false;
  }

  ngAfterViewInit(): void {
    this.handleShowViewMore(this.reviewContent, 24 * 3);
    this.cdRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const elementHeight = this.reviewContent.nativeElement.offsetHeight;

    if (elementHeight > 24 * 3) {
      this.isLongText = true;
      this.isExpanded = false;
      this.renderer.addClass(this.reviewContent.nativeElement, 'line-clamp-3');
    }

    if (elementHeight == 24 * 3) {
      this.renderer.removeClass(
        this.reviewContent.nativeElement,
        'line-clamp-3'
      );
      const actualHeight = this.reviewContent.nativeElement.offsetHeight;

      if (actualHeight > 24 * 3) {
        this.isLongText = true;
        this.renderer.addClass(
          this.reviewContent.nativeElement,
          'line-clamp-3'
        );
      } else this.isLongText = false;
      this.isExpanded = false;
    }

    if (elementHeight < 24 * 3) {
      this.isLongText = false;
      this.isExpanded = false;
    }
  }
  
  onClickImage(image_urls: any, imageIndex: number) {
    let images: string[] = [];
    image_urls.map((img: Media) => {
      let temp = `${this.baseUrl}/storage/files/web/${img.id}`;
      images.push(temp);
    });
    this.openImageRef = this.dialogService.open(ShowImageModalComponent, {
      data: {
        image_id: imageIndex,
        image_urls: images,
      },
      showHeader: false,
      width: '100%',
      height: '100%',
      baseZIndex: 10000,
      maximizable: true,
    });
    this.openImageRef.onClose.subscribe(() => {});
    this.openImageRef.onMaximize.subscribe((value: any) => {});
  }
  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/temporary/default-hotel.png';
  }

  getTranslateExperience(text: string) {
    switch (text) {
      case 'VALUE_FOR_MONEY':
      case 'Value for money':
        return 'REVIEW_MANAGEMENT.VALUE_FOR_MONEY';
      case 'FACILITIES':
      case 'Facilities':
        return 'REVIEW_MANAGEMENT.FACILITIES';
      case 'CLEANLINESS':
      case 'Cleanliness':
        return 'REVIEW_MANAGEMENT.CLEANLINESS';
      case 'SERVICES_OFFERED':
      case 'Services offered':
        return 'REVIEW_MANAGEMENT.SERVICES_OFFERED';
      case 'LOCATION':
      case 'Location':
        return 'REVIEW_MANAGEMENT.LOCATION';
      default:
        return text;
    }
  }
}
